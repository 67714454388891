import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { getOffset, smoothScrollTo } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { MediaMatchHandler } from './utilities/js/media-match-handler';
import { ResizeHandler } from './utilities/js/resize-handler';
import { Accessibility } from './layout/accessibility/accessibility';

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

window.eventScroller = new EventScroller();
window.mediaMatchHandler = new MediaMatchHandler();
window.resizeHandler = new ResizeHandler();

window.youtubeApi = typeof window.YT !== 'undefined';
window.youtubeApiLoading = false;
window.locale = document.querySelector('html').getAttribute('lang');
window.siteheaderAPI = null;

window.reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
window.reducedMotionActive = window.reducedMotion.matches;

window.oneTrustInitialized = false;
window.enabledCookies = [];

// GLOBAL OPTIONS
window.OPTIONS = { // eslint-disable-line
    loader: '<span class="loading"><span class="loader"></span></span>',
    speed: 500
};

const waitForOneTrust = (maxRetries = 50, interval = 100) => {
    let retries = 0;

    const checkInterval = setInterval(() => {
        if (typeof OneTrust !== 'undefined') {
            const initEvent = new CustomEvent('cookieConsentInitialized');
            window.oneTrustInitialized = true;
            window.enabledCookies = OnetrustActiveGroups.split(','); // eslint-disable-line
            window.dispatchEvent(initEvent);

            const handleConsentChange = () => {
                const updateEvent = new CustomEvent('cookieConsentChanged');
                window.enabledCookies = OnetrustActiveGroups.split(','); // eslint-disable-line
                window.dispatchEvent(updateEvent);
            };

            OneTrust.OnConsentChanged(handleConsentChange); // eslint-disable-line

            clearInterval(checkInterval);
        } else {
            retries++;
            if (retries >= maxRetries) {
                clearInterval(checkInterval);
            }
        }
    }, interval);
};

const reduceMotionCheck = () => {
    window.reducedMotion.addEventListener('change', (event) => {
        // Check if the reduced motion preference has changed
        if (window.reducedMotionActive !== event.matches) {
            // Update the reduced motion media query and the active state
            window.reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
            window.reducedMotionActive = window.reducedMotion.matches;

            // Create a custom event to notify the change in reduced motion preference
            const motionEvent = new CustomEvent('motionPreferenceChanged', {
                detail: {
                    prefersReducedMotion: window.reducedMotionActive
                }
            });

            // Dispatch the custom event to notify other parts of the application
            window.dispatchEvent(motionEvent);

            // Necessary to start/reset scroll animation (scroll 1px up and down to trigger smooth scrolling)
            const currentScrollPosition = window.scrollY;

            window.scrollTo({
                top: currentScrollPosition + 1,
                behavior: 'smooth'
            });

            window.scrollTo({
                top: currentScrollPosition - 1,
                behavior: 'smooth'
            });
        }
    });
};

const browserCheck = () => {
    window.browser = {
        csshover: !window.matchMedia('(pointer:coarse)').matches,
        ios: (/iPhone|iPad|iPod/i).test(navigator.userAgent),
        iphone: (/iPhone|iPod/i).test(navigator.userAgent),
        android: (/(android)/i).test(navigator.userAgent),
        firefox: (/firefox/i).test(navigator.userAgent),
        safari: (/Version\/[\d.]+.*Safari/).test(navigator.userAgent)
    };

    Object.entries(window.browser).forEach(([key, value]) => {
        document.documentElement.classList.add((!value) ? `no-${key}` : `${key}`);
    });
    document.documentElement.classList.remove('no-js');
    document.documentElement.classList.add('js');
};

window.checkInView = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            if (!entry.target.classList.contains('inview')) {
                entry.target.classList.add('inview');

                if (typeof entry.target.dataset.footer === 'undefined') {
                    window.inViewObserver.unobserve(entry.target);
                }
            }
        }
    });
};

window.inViewObserver = new IntersectionObserver(window.checkInView, {
    threshold: 0.1
});

const initContent = ($content) => {
    console.log('start app');

    const $$anchor = $content.querySelectorAll('a[href*="#"]:not([data-anchor-navigation])');
    
    for (let i = 0; i < $$anchor.length; i++) {
        const $anchor = $$anchor[i];
        let anchorTimeout = null;

        $anchor.addEventListener('click', (e) => {
            let baseUrl = $anchor.getAttribute('href').split('#')[0];
            const targetId = $anchor.getAttribute('href').split('#')[1];
            const $target = document.getElementById(targetId);
            const currentUrl = window.location.href;
            let focusTimer = null;

            if (baseUrl === '') {
                baseUrl = currentUrl;
            }

            if (baseUrl === currentUrl && $target !== null) {
                const anchorDimensions = getOffset($target);
                const anchorDimensionsTop = anchorDimensions.top;

                smoothScrollTo(0, anchorDimensionsTop, window.OPTIONS.speed);

                $target.setAttribute('tabindex', 0);
                $target.focus();

                focusTimer = setTimeout(() => {
                    $target.removeAttribute('tabindex');
                    clearTimeout(focusTimer);
                }, 200);

                if (window.location.hash && window.siteheaderAPI) {
                    clearTimeout(anchorTimeout);
                    anchorTimeout = window.setTimeout(() => {
                        window.siteheaderAPI.toggleHeader(false);
                    }, 500);
                }
                e.preventDefault();
            }
        });

        if (window.location.hash && window.siteheaderAPI) {
            clearTimeout(anchorTimeout);
            anchorTimeout = window.setTimeout(() => {
                window.siteheaderAPI.toggleHeader(false);
            }, 500);
        }
    }

    loadLazyImages();
};

domready(function () {    
    const initApplication = () => {
        browserCheck();
        reduceMotionCheck();
        waitForOneTrust();

        window.eventScroller.init();
        window.resizeHandler.init();

        window.dataInview = document.querySelectorAll('[data-inview]:not(.inview)');
        setTimeout(() => {
            window.dataInview.forEach((dataInView) => {
                window.inViewObserver.observe(dataInView);
            });
        }, 500);

        // Accessibility
        const accessibilityAPI = new Accessibility();
        accessibilityAPI.initialize();

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        document.body.classList.add('page--loaded');
        window.addEventListener('beforeunload', () => {
            const isMailTo = document.activeElement.protocol === 'mailto:';
            const isTel = document.activeElement.protocol === 'tel:';

            if (!(isMailTo && isTel)) {
                document.body.classList.remove('page--loaded');
            }
        });

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });

        // jump to main content and set focus on container
        document.querySelector('.hidden-shortcut a[href="#main"]')?.addEventListener('click', () => {
            document.querySelector('#main').focus();
        });

        const $$cookieConsentOverlayOpener = document.querySelectorAll('a[href="cookie-consent"]');

        $$cookieConsentOverlayOpener.forEach($cookieConsentOverlayOpener => {
            
            $cookieConsentOverlayOpener.addEventListener('click', (e) => {
                if (typeof OneTrust !== 'undefined') { // eslint-disable-line
                    OneTrust.ToggleInfoDisplay(); // eslint-disable-line
                }
                e.preventDefault();
            });
        });

        initContent(document);
    };

    initApplication();
});
